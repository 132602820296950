.container {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}

.flex_row_center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}