* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

button {
  cursor: pointer;
}

