@font-face {
  font-family: "Walbaum 60pt";
  src: url("../font/Walbaum60pt.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "TT Together";
  src: url("../font/TTTogether.ttf") format("truetype");
  font-weight: normal;
}


@font-face {
  font-family: 'Walbaum 60ptL';
  src: url("../font/walbaum60ptlight-webfont.eot") format("embedded-opentype"),
  url("../font/walbaum60ptlight-webfont.svg") format("svg"),
  url("../font/walbaum60ptlight-webfont.ttf") format("truetype"),
  url('../font/walbaum60ptlight-webfont.woff2') format('woff2'),
  url('../font/walbaum60ptlight-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

.form_page {
  .container {

    .banner {
      img {
        width: 100%;
      }
    }

    .form_box_column {
      border: 1px solid #D9D9D9;

      .form_title {
        display: flex;
        flex-direction: row;

        .title {
          width: 25%;
          flex: 0 0 25%;
          background-color: #EEEEEE;

          &:not(:last-of-type) {
            border-right: 1px solid #D9D9D9;
          }

          h5 {
            font-weight: 700;

            span {
              color: red;
            }
          }
        }
      }

      .form_content {
        display: flex;
        flex-direction: row;

        .form_item {
          width: 25%;
          flex: 0 0 25%;

          &:not(:last-of-type) {
            border-right: 1px solid #D9D9D9;
          }

          .content {
            padding: 0.5rem 1rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            word-break: break-all;

            input {
              width: 100%;
              height: 30px;
              line-height: 30px;
              border: 1px solid #D9D9D9;
            }
          }
        }
      }

    }

    .form_box_column {
      .content {
        position: relative;
        display: flex;
        flex-direction: column !important;

        .validation_msg {
          width: 100%;
          text-align: right;
          margin-top: 0.5rem;
          color: red;
        }
      }
    }

    .form_box_row {
      display: flex;
      position: relative;

      .title {
        flex: 0 0 30%;
        background-color: #EEEEEE;
        border-top: 1px solid #D9D9D9;
        border-left: 1px solid #D9D9D9;
        border-bottom: 1px solid #D9D9D9;

        h5 {
          font-weight: 700;

          span {
            color: red;
          }
        }
      }

      .content {
        overflow: hidden;
        flex: 0 0 70%;
        padding: 1rem;
        border: 1px solid #D9D9D9;

        label {
          margin-right: 1rem;
        }

        p {
          span {
            color: red;
          }
        }

        textarea {
          width: 100%;
          min-height: 100px;
          margin-top: 1rem;
          border-color: #D9D9D9;
        }

        .msc_input {
          height: 30px;
          border: 1px solid #D9D9D9;
          width: 50%;
        }
      }

      .validation_msg {
        position: absolute;
        right: 0;
        bottom: 0;
        color: red;
      }
    }

    .add_form {
      button {
        width: 50%;
        display: inline-block;
        background: none;
        border: none;
        cursor: pointer;
        padding: 0.5rem 0;

        &:first-child {
          background-color: #F49C50;
        }

        &:last-child {
          background-color: #a0a0a0;
        }

        &:hover {
          opacity: 0.5;
        }


        svg {
          width: 25px;
          height: 25px;
          fill: #FFF;
        }
      }
    }

    .terms {
      background-color: #EEEEEE;
      text-align: center;
      padding: 1rem;

      p {
        color: #222;
        margin-bottom: 0.5rem;
        font-size: 0.875rem;
      }

      label {
        margin: 0 0.5rem;
      }
    }

    #submit_btn {
      width: 100%;
      padding: 1rem 0;
      color: #FFF;
      font-weight: 700;
      border: none;
      background-color: #9e9e9e;
      pointer-events: none;

      &.active {
        background-color: #F49C50;
        pointer-events: auto;
      }
    }

  }
}

.form_list_page {

  .page_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;


    .page_functions {
      display: flex;
      align-items: center;
      button {
        background: #FFF;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        border-radius: 6px;
        color: #F49C50;
        font-size: 12px;
        padding: 0.3rem 1rem;
        margin-right: 1rem;
        min-width: 70px;
        border: 1px solid #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        height: 45px;
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  .form_list_table {
    width: 100%;
    border-collapse: collapse;

    thead {
      th {
        background-color: #F49C50;
        color: #FFF;
      }
    }

    thead, tr, tbody, td, tfoot, th {
      border-color: inherit;
      border-style: solid;
      border-width: 0;
    }

    th, td {
      padding: 0.85rem;
    }

    tbody {
      tr {
        cursor: pointer;

        &:hover {
          background-color: rgba(235, 240, 243, 0.6);
        }
      }

      td {
        border-bottom: 1px solid #e5e8eb;
        font-size: 0.85rem;
      }
    }

  }
}


.form_detail_page {
  padding: 1rem;
  background-color: #FFF;

  .fc_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    button {
      background: #FFF;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      border-radius: 6px;
      color: #F49C50;
      font-size: 12px;
      padding: 0.6rem 1rem;
      margin-right: 1rem;
      min-width: 70px;
      border: 1px solid #FFF;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  * {
    font-family: 'Walbaum 60ptL', serif;
    font-weight: bold;
    letter-spacing: 1.3px;
  }

  .title, .name, .affiliation, .email, .bottom_msg {
    text-align: center;
  }

  .affiliation {
    margin: 0.5rem 0;
  }

  .title {
    margin-bottom: 1rem;

    p {
      font-family: 'Walbaum 60pt', serif;
      font-size: 1.5rem;
    }
  }

  .name, .affiliation, .email {
    p {
      font-size: 14px;
    }
  }


  .abstract, .bottom_msg {
    p {
      font-size: 1rem;
    }
  }

  .abstract {
    margin-top: 1.5rem;
  }

  .references, .keywords {
    .references_title, .keywords_title {
      text-align: center;
      margin: 1.5rem 0 1rem 0;
      font-size: 1.2rem;

      span {
        font-size: 0.95rem;
        font-family: 'Walbaum 60pt', serif;
        font-weight: 500;
      }
    }

    .references_content {
      padding: 0 1.2rem;

      ol {
        padding: 0;

        li {
          margin-bottom: 0.2rem;
        }
      }
    }

    .references_content, .keywords_content {
      font-size: 0.9rem;
    }
  }

  .bottom_msg {
    width: calc(100% - 40mm);
    margin-top: 5rem;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, -50%);

    hr {
      max-width: 130px;
      text-align: left;
      margin: 0;
    }

    p {
      font-size: 0.8rem;
    }
  }
}

.ref_form {
  display: flex;
  flex-direction: column;

  input {
    width: 90%;
    height: 30px;
    line-height: 30px;
    border: 1px solid #D9D9D9;
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
  }
}


.page {
  position: relative;
  width: 210mm;
  min-height: 297mm;
  padding: 20mm;
  margin: 10mm auto;
  border: 1px #D3D3D3 solid;
  border-radius: 5px;
  background: white;

}


@page {
  size: A4;
  margin: 0;
}

@media print {

  p, span, h5 {
    font-weight: bold;
    letter-spacing: 1.3px;
    color: black;

  }

  html, body {
    width: 210mm;
    height: 297mm;
  }


  .page {
    margin: 0;
    border: none;
    box-shadow: none;
    border-radius: initial;
    width: initial;
    min-height: initial;
    background: initial;
    page-break-after: always;
    position: relative;

    .title, .name, .affiliation, .email, .bottom_msg {
      text-align: center;
    }

    .bottom_msg {
      margin-top: 5rem;
      position: absolute;
      top: 250mm;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
  }
}

.references{
  margin-bottom: 5rem;
}

.MJXc-display {
  display: unset !important;
  margin: 0 !important;
}

.auth_input {
  width: 100%;
  border: 1px solid #D9D9D9;
  height: 40px;
  padding: 0 1rem;
}